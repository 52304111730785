import React from "react";
import { Link } from "gatsby";
import { Dropdown, Container, Row, Col } from "react-bootstrap";
import SideBarMenu from "@components/sidebar/sidebar";
import Sticky from "react-sticky-el";
// import styl from "./header.scss"

import Logo from "@images/webriq-logo.png";

const Header = () => (
  <header>
    <Sticky
      className="sticky-wrapper"
      stickyClassName="is-sticky"
      stickyStyle={{ transform: "unset", zIndex: "9999" }}
    >
      <div className="header">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/">
              <img className="header-logo" src={Logo} alt="LeadRoll" />
            </Link>
            <div className="d-flex">
              <div className="d-none d-md-flex">
                <Dropdown>
                  <Dropdown.Toggle variant="link" bsPrefix="menu-link">
                    Case Studies
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Container>
                      <Row>
                        <Col sm={6}>
                          <h4>Notable</h4>
                          <Link className="menu-link d-block mx-0" to="/">
                            Action
                          </Link>
                          <Link className="menu-link d-block mx-0" to="/">
                            Another action
                          </Link>
                          <Link className="menu-link d-block mx-0" to="/">
                            Something else
                          </Link>
                        </Col>
                        <Col sm={6}>
                          <h4>Notable</h4>
                          <Link className="menu-link d-block mx-0" to="/">
                            Action
                          </Link>
                          <Link className="menu-link d-block mx-0" to="/">
                            Another action
                          </Link>
                          <Link className="menu-link d-block mx-0" to="/">
                            Something else
                          </Link>
                        </Col>
                      </Row>
                    </Container>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Link
                to="/"
                target="_blank"
                rel="noreferrer"
                title="Get a Price"
                className="btn btn-primary header-cta"
              >
                Get a Price
              </Link>
              <div className="d-inline-block d-md-none">
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
);

export default Header;
