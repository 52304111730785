import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Header from "@components/header/header";
import Footer from "@components/footer/footer";
// import "@components/layout.scss"
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import Helmet from "react-helmet";
import "@sass/main.scss";

const Layout = ({ children, location }) => {
  console.log(location.pathname);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      {/* <Helmet>
        <link
          rel="stylesheet"
          href="https://static.small.chat/messenger.css"
        ></link>
        <script
          text="text/javascript"
          src="https://embed.small.chat/T014R9XTV7WG015X2S6EEN.js"
        ></script>
      </Helmet> */}
      {location.pathname !== "/deposit/" &&
      location.pathname !== "/deposit" &&
      location.pathname !== "/thank-you" &&
      location.pathname !== "/thank-you/" ? (
        <Header siteTitle={data.site.siteMetadata.title} />
      ) : (
        ""
      )}

      <main>{children}</main>
      {location.pathname !== "/deposit/" &&
      location.pathname !== "/deposit" &&
      location.pathname !== "/thank-you" &&
      location.pathname !== "/thank-you/" ? (
        <Footer />
      ) : (
        ""
      )}
      {/* <div id="Smallchat"></div> */}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
