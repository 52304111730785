import React from "react";
import { Link } from "gatsby";

const footer = (props) => {
  return (
    <div className="footer bg-dark-blue">
      <div className="container">
        <div className="row">
          <div className="col-md-5 mb-5">
            <h6 className="text-light-blue text-uppercase pb-3">Contact Us</h6>
            <ul className="list-unstyled contact-list">
              <li className="email">
                <a className="large-link" href="mailto:info@webriq.com">
                  info@webriq.com
                </a>
              </li>
              {/* <li className="phone">
                <a className="large-link" href="/">
                  123-4567
                </a>
              </li> */}
            </ul>
          </div>
          <div className="col-md-3 mb-5">
            <h6 className="text-light-blue text-uppercase pb-3">
              Helfpul Links
            </h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/">Terms of Service</Link>
              </li>
              <li>
                <Link to="/">Account Service</Link>
              </li>
              <li>
                <Link to="/">FAQs</Link>
              </li>
              <li>
                <Link to="/">Blog</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 mb-5">
            <h6 className="text-light-blue text-uppercase pb-3">
              We're Social
            </h6>
            <ul className="list-unstyled"></ul>
            <a
              className="social-icon facebook"
              href="https://www.facebook.com/webriq"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="facebook"
            >
              <i className="fa fa-facebook"></i>
            </a>
            <a
              className="social-icon linkedin"
              href="https://www.linkedin.com/company/webriq/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="linkedin"
            >
              <i className="fa fa-linkedin"></i>
            </a>
          </div>
        </div>
        <span className="text-white-50 small">
          © {new Date().getFullYear()} WebriQ. All rights reserved.
        </span>
      </div>
      <div className="blob-bg"></div>
    </div>
  );
};

export default footer;
