import React from "react";
import { Link } from "gatsby";
import { Nav } from "react-bootstrap";

import LogoWhite from "@images/webriq-logo.png";

export default ({ toggle }) => (
  <div className="navigation">
    <button id="close-sidebar" onClick={toggle} aria-label="close">
      <i className="fa fa-close"></i>
    </button>
    <Link to="/">
      <img className="sidebar-logo" src={LogoWhite} alt="Lead Roll" />
    </Link>
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">Home</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/">Channel</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/">Sign In</Link>
      </Nav.Item>
    </Nav>
    <Link to="/" className="btn btn-primary sidebar-cta m-0">
      Get a Price
    </Link>
  </div>
);
